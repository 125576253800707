<template>
  <b-row class="match-height">
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="UserIcon"
            :statistic="$t('campaigns.followers')"
            :statistic-title="$t('youtube.duringlast')"
            :statisticText="`${this.$t('youtube.total')} ${format(metrics.subscribers_count.value)}`"
            :chart-data="[{name: $t('campaigns.followers'), data: seriesSubs}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="UserIcon"
            :statistic="$t('tiktok.susGrowth')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: '%', data: seriesGrowth}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="FileIcon"
            :statistic="$t('youtube.contents')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: $t('youtube.contents'), data: seriesMedia}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="FileIcon"
            :statistic="$t('twitter.contensWeek')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: $t('youtube.contents'), data: seriesMediaWeek}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="RepeatIcon"
            :statistic="$t('twitter.averageRet')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: $t('twitter.retweets'), data: seriesRetweet}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="RepeatIcon"
            :statistic="$t('twitter.quotedAverage')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: '%', data: seriesRetweetQuoted}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="HeartIcon"
            :statistic="$t('twitter.averageFav')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: $t('twitter.favorites'), data: seriesFav}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="MessageCircleIcon"
            :statistic="$t('twitter.averageRes')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name:$t('twitter.responses'), data: seriesReply}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="TwitterIcon"
            :statistic="$t('twitter.tweetLinks')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: '%', data: seriesTweetLink}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="TwitterIcon"
            :statistic="$t('twitter.tweetContent')"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: '%', data: seriesTweetMedia}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
    <b-col lg="3" md="3" sm="6">
      <statistic-card-with-line-chart
            icon="TwitterIcon"
            statistic="ER"
            :statistic-title="$t('youtube.duringlast')"
            :chart-data="[{name: '%', data: seriesER}]"
            :color="'successs'"
            :chartOptions="chartOptions"
            :heightChart="130"
          />
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { getSeries } from '@/libs/utils/series_graphs'
import { convertData, getFormat } from '@/libs/utils/formats'
export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithLineChart
  },
  props: {
    metrics: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getFormat,
      convertData,
      getSeries,
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 30,
            right: 30,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['30d', '90d', '180d'],
          show: true,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            show: true,
            offsetY: -5,

          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
    }
  },
  computed: {
    seriesSubs() {
      return getSeries(convertData(this.metrics.subscribers_count.performance),'value')
    },
    seriesGrowth() {
      return getSeries(convertData(this.metrics.subscribers_growth_prc.performance),'value')
    },
    seriesMedia() {
      return getSeries(convertData(this.metrics.media_count.performance),'value')
    },
    seriesMediaWeek() {
      return getSeries(convertData(this.metrics.media_per_week.performance),'value')
    },
    seriesRetweet() {
      return getSeries(convertData(this.metrics.retweet_avg.performance),'value')
    },
    seriesRetweetQuoted() {
      return getSeries(convertData(this.metrics.quoted_retweet_rate.performance),'value')
    },
    seriesFav() {
      return getSeries(convertData(this.metrics.favorite_avg.performance),'value')
    },
    seriesReply() {
      return getSeries(convertData(this.metrics.reply_avg.performance),'value')
    },
    seriesTweetLink() {
      return getSeries(convertData(this.metrics.tweets_with_links_prc.performance),'value')
    },
    seriesTweetMedia() {
      return getSeries(convertData(this.metrics.tweets_with_media_prc.performance),'value')
    },
    seriesER() {
      return getSeries(convertData(this.metrics.er.performance),'value')
    },
  },
  methods: {
    format(num) {
      return getFormat(num)
    }
  }
}
</script>
